<template>
  <NextDayMetrics />
</template>

<script>
import NextDayMetrics from '@/components/pages/sales/NextDayMetrics'

export default {
  components: {
    NextDayMetrics
  }
}
</script>
